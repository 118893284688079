import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { buildBlocks } from "../services/builder"
import Breadcrumb from "../components/Breadcrumb"
import { CoreFields } from "./fragment" // eslint-disable-line
import { CoreBlockFragment } from "../components/blocks/CoreBlock" // eslint-disable-line
import CookiesConsent from "../components/CookiesConsent"

class Page extends Component {
  render() {
    const blocks = buildBlocks(
      this.props.data.wpPage.blocks,
      {},
      { faqs: this.props.pageContext.faqs },
      this.props.data.wpPage.id
    )

    return (
      <Layout
        meta={{
          ...this.props.data.wpPage.Meta,
          ogImage: this.props.data.wpPage.featuredImage?.node.localFile
            .childImageSharp?.fixed?.src,
          twitterImage: this.props.data.wpPage.featuredImage?.node.localFile
            .childImageSharp?.fixed?.src,
        }}
        path={this.props.pageContext.pagePath}
        faqs={this.props.pageContext.faqs}
        cta={this.props.data.wpPage.PageCTA}
        title={this.props.data.wpPage.title}
        themeOptions={this.props.data.wp.themeOptions}
      >
        {this.props.data.wpPage.title !== "Home" && (
          <Breadcrumb
            type="page"
            ancestors={this.props.data.wpPage.ancestors}
            current={{
              title: this.props.data.wpPage.title,
              slug: this.props.data.wpPage.slug,
              uri: this.props.data.wpPage.uri,
            }}
          />
        )}
        {blocks}
        <CookiesConsent></CookiesConsent>
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String) {
    wpPage(id: { eq: $id }) {
      id
      slug
      title
      uri
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fixed(width: 300, height: 200) {
                src
              }
            }
          }
        }
      }
      ancestors {
        nodes {
          id
          uri
          slug
          ... on WpPage {
            title
          }
        }
      }
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Page
